.table {
  &__wrapper {
    margin: 50px auto;
    border-radius: 15px;
    overflow: hidden;
  }
  &__content {
    width: 400px;
    display: flex;
    justify-content: center;
  }
  &__inner {
    &-title {
      box-sizing: border-box;
      flex-basis: 40%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      background-color: $c-frenchPass;
      border: 1px solid white;
      .button-link__danger {
        margin: 0;
        font-size: 90%;
      }

      &-main {
        flex-basis: 40%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        background-color: $c-frenchPass;
        border: 1px solid white;
        flex-basis: 100%;
      }
    }
    &-data {
      box-sizing: border-box;
      flex-basis: 60%;
      background-color: $c-main;

      flex-flow: column;
      display: flex;

      &--section {
        border: 1px solid $c-white;
        flex-basis: 50%;

        h3 {
          margin-bottom: 0;
        }
        .text-info {
          color: $c-white;
          line-height: 1em;
          margin-top: 10px;
          font-size: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .table__content {
    width: auto;
  }
  .table__inner-title {
    flex-basis: 100%;
  }
  .table__inner-data {
    flex-basis: 100%;
  }
}
