.main-footer {
  &-none {
    display: none;
  }
  &__navbar {
    list-style: none;
    justify-content: center;

    li {
      display: inline-block;
      padding: 10px 20px 30px;
    }
  }

  &__link {
    color: $c-textInfo;
    display: inline-block;
    transition: all 0.5s;
    &:hover {
      color: $c-white;
    }
  }
  &__top {
    flex-wrap: nowrap;
    margin-top: 40px;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 25px;
    }
    img {
      object-fit: scale-down;
      align-self: flex-end;
      margin-left: -65px;
    }

    .grid-col-6 {
      padding-top: 100px;
      padding-left: 50px;
      margin-left: 50px;
      box-sizing: border-box;
    }

    .btn-content {
      background-color: $c-coralred;
      color: $c-white;
      width: 350px;
      padding: 10px 20px;
      margin: 0 auto;
      font-size: 25px;
    }
  }

  &__bottom {
    &-nav {
      background-color: $c-tundora;
      height: 150px;
      display: flex;
      align-items: flex-end;
    }

    &-content {
      margin: 0 auto -75px;
      position: relative;
      width: 100vw;
      max-width: $wrapper;
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;
      padding: 0 100px;
    }

    &-contact,
    &-info {
      color: $c-white;
      height: 150px;

      p {
        margin-top: 35px;
        font-size: 20px;
      }
      a,
      h3 {
        color: $c-white;
        font-size: 22px;
      }
    }
    &-contact a,
    &-info h3 {
      color: $c-white;
      font-size: 22px;
    }
    &-contact {
      background-color: $c-main;
      border-radius: 20px 0 0 0;
    }
    &-info {
      background-color: $c-sub;
      border-radius: 0 20px 0 0;
    }
  }
}
.sub-footer-img {
  padding: 50px 0;
  max-width: $wrapper-high;
}
@media screen and (max-width: 800px) {
  .main-footer__top {
    display: none;
  }
  .main-footer__bottom-content {
    margin: 0;
    padding: 0;
  }
  .main-footer__bottom-info,
  .main-footer__bottom-contact {
    flex-basis: 100%;
    border-radius: 0;
  }

  .main-footer__navbar li {
    padding: 10px;
  }
  .main-footer__top {
    .grid-col-6 {
      padding-left: 0px;
      margin-left: 0px;
    }
    img {
      display: none;
    }
  }

  .sub-footer-img {
    display: none;
  }
}
