.main-header {
  &__navbar {
    position: relative;
    &-toggle {
      cursor: pointer;
    }
    &-wrapper {
      position: absolute;
      top: 0;
      padding: 20px;
      width: 200px;
      border-radius: 20px;
      z-index: 10;
      background: $c-tundora;
      margin: 0;
      ul {
        list-style-type: none;

        text-align: left;

        color: $c-white;

        li {
          margin-bottom: 10px;
        }

        
      }
      &--close {
          display: none;
        }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      font-size: 20px;

      &:hover {
        color: $c-sub;
      }
    }
  }

  &__link {
    cursor: pointer;
    margin: 10px 0;
    color: $c-white;

    &:hover {
      color: $c-main;
    }
  }
}

@media screen and (max-width: 800px) {
  .main-header {
    &__navbar-wrapper {
      right: 0;
      li {
        margin-bottom: 20px;
        font-size: 25px;
      }
    }
    &__navbar-close {
      font-size: 30px;
    }
  }
      .mobileNone { 
      display: none;
     }
}
