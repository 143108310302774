@import './variables';
@import './normalize';

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto+Slab|Roboto:300,400,500,700&display=swap');
* {
  font-family: 'Roboto Light', sans-serif;
}
html {
  font-size: 16px;
}

h1,h2 {
  font-family: 'Roboto Black', sans-serif;
  font-weight: 900;
}
body {
  font: normal normal normal 1rem $f-baseFontFamily;
  overflow-x: hidden;
  color: $c-mainShaft;
  background-color: #fff;
  margin:0;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.country-selector,
.search-box,
.main-title {
  display: none !important;
}

.page-container {
  margin: 0 auto;
  max-width: $wrapper;
}
img {
  max-width: 100%;
}
@media screen and (max-width: $break-high) {
  .page-container {
    max-width: $wrapper-high;
  }
}

@media screen and (max-width: $break-laptop) {
  .page-container {
    max-width: $wrapper-laptop;
  }
}

@media screen and (max-width: $break-mid) {
  .page-container {
    max-width: $wrapper-mid;
  }
}