.main-header {
  &__top {
    height: 150px;
    display: flex;
    align-items: center;
    max-width: $wrapper-high;
    margin: 0 auto;

    &-inner {
      display: flex;
    }
  }

  &__bottom {
    background: url("../../../../img/header-background.jpg") no-repeat top
      center;
    background-size: cover;
    min-height: calc(100vh - 150px);
    padding: 90px 0 50px;
    box-sizing: border-box;

    h1 {
      color: $c-white;
      font-size: 50px;
      text-shadow: 1px 1px 2px grey;
    }
    a {
      color: $c-white;
      font-size: 22px;
    }
  }

  &__logo {
    &-img {
      height: 100px;
    }
  }

  &__image-social {
    padding: 10px;
    width: 45px;
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button-link {
      width: 160px;
      margin: 10px;
      &--mobile {
        display: none;
      }
    }
  }
}

.sub-header {
  position: relative;
  background: url("../../../../img/header-background2.jpg") no-repeat top center;
  background-size: cover;
  min-height: 40vh;
  padding: 50px 0;
  box-sizing: border-box;
  h1 {
    color: $c-white;
    font-size: 50px;
  }
  &:after {
    position: absolute;
    left: calc(50% - 10px);
    content: "";
    border: 1px solid $c-silver;
    bottom: -10px;
    width: 20px;
    height: 20px;
    background-color: $c-white;
    transform: rotateZ(45deg);
  }
  a{
    color: $c-white;
      font-size: 22px;
  }
}

@media screen and (max-width: 800px) {
  
  .main-header {
    &__logo {
      flex-basis: unset;
      
      img {
        height: unset;
      }
    }
    
    &__bottom {
      margin-top: 20px;
      background-position: top left;
      padding: 20px 0 50px;
    }
    
    &__content { 
      flex-basis: 70%;
      
      .button-link {
        display: none;
      
        &--mobile {
          display: block;
          padding: 10px 15px;
        }
      }
    }
    
    &__top-inner {
      flex-wrap: nowrap;
    }
    
    .mobileNone { 
      display: none;
     }
  }
}
