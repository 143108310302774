.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  min-height: 300px;
}

.App-link {
  color: #61dafb;
}

.set-background {
  background: url("../../../../img/home-background1.png") no-repeat center center;
  background-size: contain;
}

.error-page {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    background-color: $c-white;
    img{
      width: 100%;
    }
  }