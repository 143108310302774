.section-home {
  position: relative;
  &:after {
    position: absolute;
    left: calc(50% - 10px);
    content: "";
    border: 1px solid $c-silver;
    bottom: -10px;
    width: 20px;
    height: 20px;
    background-color: $c-white;
    transform: rotateZ(45deg);
  }

  &__cezar {
    background: url("../../../../img/cezar.png") center center no-repeat;
    width: 250px;
    height: 250px;
    background-size: contain;
    padding: 50px;
    box-sizing: border-box;
    font-size: 26px;
    line-height: 1.6em;
    margin: 0 auto;
    text-shadow: 1px 1px 2px white;
    font-family: roboto;
    font-family: "Roboto Black", sans-serif;
    strong {
      display: block;
      font-size: 200%;
      font-weight: 900;
    }
  }

  &__content .button-link {
    font-weight: bold;
  }

  &-charts {
    background: url("../../../../img/background2.png") no-repeat top center;
    background-size: 100% 120%;
    padding: 1px 0;
    position: relative;
    min-height: 800px;

    img {
      width: 300px;
      padding-top: 20px;
    }

    &:before {
      position: absolute;
      left: calc(50% - 10px);
      content: "";
      top: -10px;
      border: 1px solid $c-silver;
      width: 20px;
      height: 20px;
      background-color: $c-white;
      transform: rotateZ(45deg);
    }
  }

  &__step-by-step {
    background-color: $c-athensGray;
    padding: 30px;
    padding-bottom: 60px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    &:before,
    &:after {
      position: absolute;
      left: calc(50% - 10px);
      content: "";
      border: 1px solid $c-silver;
      width: 20px;
      height: 20px;
      background-color: $c-white;
      transform: rotateZ(45deg);
    }
    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }
  }
  &__background-top {
    background: url("../../../../img/home-background1.png") no-repeat top center;
  }
  &__background-mid {
    background: url("../../../../img/home-background2.png") no-repeat center center;
  }
}
.section-title__info {
  width: 50%;
}
.info {
  &-wrapper {
    padding: 50px;
    box-sizing: border-box;
  }
  &-section {
    &__close {
      position: absolute;
      top: 30px;
      right: 30px;
      border: 2px solid $c-bombay;
      color: $c-bombay;
      border-radius: 100%;
      padding: 0px 9px 3px;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
      display: none;

      &:hover {
        color: $c-sub;
        border-color: $c-sub;
      }
    }

    &__blue-img,
    &__pink-img,
    &__green-img {
      .section-title {
        font-size: 40px;
        margin-bottom: 0;
      }
      .text-info {
        position: relative;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          width: 0;
          height: 2px;
          margin: 5px 0 0;
          left: -10%;
          transition: all 0.5s ease-in-out;
          opacity: 1;
        }
        &:hover:after {
          width: 120%;
        }
      }

      &.toggle {
        transition: all 0.5s;
        height: 0;
        opacity: 0;
      }
    }

    &__blue-img {
      .text-info {
        color: $c-main;
        &:after {
          background-color: $c-main;
        }
      }
    }

    &__pink-img {
      .text-info {
        color: $c-sub;
        &:after {
          background-color: $c-sub;
        }
      }
    }

    &__green-img {
      .text-info {
        color: $c-mintGreen;
        &:after {
          background-color: $c-mintGreen;
        }
      }
    }
    &__blue-description,
    &__pink-description,
    &__green-description {
      background-color: $c-white;
      padding: 20px;
      height: 0;
      overflow: hidden;
      transition: 1s all;
      position: relative;
      opacity: 0;
      &.toggle {
        height: unset;
        transition: 2s all;
        opacity: 1;
        .info-section__close {
          display: block;
        }
      }
    }

    &__blue-description {
      .section-title {
        color: $c-main;
      }
    }

    &__pink-description {
      .section-title {
        color: $c-sub;
      }
    }

    &__green-description {
      .section-title {
        color: $c-mintGreen;
      }
    }
  }
}


@media screen and (max-width: 800px){
  .section-home__step-by-step {
    img{ 
    display: none; }
  }
  .section-title__info {
  width: 100%;
  padding: 10px 20px;
}
  .info-wrapper {
    padding: 20px;
    img {
      box-sizing: border-box;
  }
  .info-section__blue-img .section-title, .info-section__pink-img .section-title, .info-section__green-img .section-title {
    font-size: 30px;
    margin-top: 0;
  }
}
}