/*.main-header__top,
.main-footer {
  display: none;
}*/

.game-section-wrapper {
  background-color: #eaebef;
  min-height: calc(100vh - 100px );
}

