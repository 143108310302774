/* =Colors
************************************************************** */

$c-white: #fff;
$c-black: #000;
$c-anakiwa: #90ccff;
$c-pinkSalmon: #ff969a;
$c-tundora : #4e4e4e;
$c-silver: #c4c4c4;
$c-coralred: #ff4651;
$c-bombay: #b3b3b4; 
$c-mintGreen : #8af595;
$c-athensGray : #f2f3f7;
$c-frenchPass : #b8dffe;
$c-cornflowerBlue :#6195ED;
// main colors
$c-main: $c-anakiwa;
$c-sub: $c-pinkSalmon;
$c-textInfo: $c-silver;
$c-lochmara: #0089d9;
$c-gray : $c-bombay;

//hover,visited link
$c-accentPrimary:$c-lochmara;

$c-amethyst: #9e72c7;
$c-mediumRedViolet: #ba2e99;
$c-seance: #6F12AF;

$c-frenchRose: #F44384;
$c-mainShaft: #323232;
$c-titanWhite: #f4f2ff;
$c-wildWatermelon: #ff5684;
$c-christalle: #390163;
$c-eminence: #613482;
$c-carissma: #ed729f;
$c-affair: #754e92;
$c-cherryPie: #310158;

/* =fonts
************************************************************** */

$f-fontAwesome: "Font Awesome 5 Free";
$f-fontAwesomeBrands: "Font Awesome 5 Brands";
$f-partnerThin: "PartnerThinUltraCondensed";
$f-baseFontFamily: "ApercuPro";
$f-apercuProMedium: "ApercuPro-Medium";


/* =breaks
************************************************************** */

$break-xs: 480px;
$break-mobile: 767px;
$break-mid: 991px;
$break-laptop: 1299px;
$break-high: 1600px;


/* =minimum device sizes
************************************************************** */

$tablet-min: 768px;
$desktop-min: 1200px;

/* =Grid
************************************************************** */

$wrapper: 1302px;
$wrapper-high: 1200px;
$wrapper-laptop: 990px;
$wrapper-mid: 800px;
$wrapper-mobile: 480px;
$columns: 12;
$padding: 15px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1199px;

/* =Paddings, Margins
************************************************************** */

$space-x-small: 10px;
$space-small: 15px;
$space-standard: 20px;
$space-medium: 30px;
$space-large: 40px;
$space-x-large: 50px;

/* =Buttons
************************************************************** */

$button-small: 175px;
$button-medium: 250px;
$button-large: 375px;