.btn {
  border-radius: 2em;
  transition: all 0.5s;
  cursor: pointer;
  padding: 18px;
  display: block;

  &:hover {
    transform: scale(1.1);
  }

  &--game {
    background-color: $c-cornflowerBlue;
    border-radius: 15px;
    box-shadow: 0 3px #999;
    transition: all 0.1s;

    &:hover {
      transform: unset;
      background: #0b7dda;
    }
    &:active {
      background-color: #0b15da;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
    }
  }
}

.btn-content {
  border-radius: 2em;
}

.button-link {
  padding: 18px 20px;
  color: $c-white;
  text-decoration: none;
  margin: 0 auto;

  &__space-standard {
    margin: 20px auto;
    padding: 10px 20px;
  }
  &-sm {
    width: $button-small;
  }
  &-md {
    width: $button-medium;
  }
  &-lg {
    width: $button-large;
  }
  &__main {
    background-color: $c-main;
  }

  &__secondary {
    background-color: $c-sub;
    &--reversed {
      color: $c-sub;
      background-color: $c-white;
    }
  }

  &__danger {
    background-color: $c-coralred;
    font-size: 20px;

    a {
      color: $c-white;
    }
  }
  &__demo {
    box-sizing: border-box;
    position: relative;
    color: $c-sub;
    background-color: $c-white;
    border: 2px solid $c-sub;
    text-align: left;
    padding: 18px 36px;
    font-weight: 600;
    text-align: center;
    &::after {
      content: '';
      box-sizing: border-box;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      border-right: 2px solid $c-sub;
      border-top: 2px solid $c-sub;
      transform: rotate(45deg) translateY(-50%);
      right: 30px;
      top: 50%;
    }
  }
}

.button-float {
  position: fixed;
  top: 45%;
  left: -67px;
  transform: rotate(-90deg);
  z-index: 100;
  background: $c-coralred;
  padding: 15px 55px;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: $c-white;
  }
}

.button-img {
  &__na-raty {
    width: 200px;
    position: relative;
    height: 200px;
    &--mobile {
      display: none;
    }
  }
  &__oferta {
    width: 200px;
    height: 200px;
    margin-top: 10%;
    &--mobile {
      display: none;
    }
  }
}

.button-submit {
  margin: auto;
  align-content: center;
  margin-top: 20px;
  width: 100px;
  padding: 10px;
}
@media screen and (max-width: 800px) {
  .button-float {
    display: none;
  }

  .button-link {
    max-width: 300px;
  }
  .button-img {
    &__oferta,
    &__na-raty {
      display: none;
      &--mobile {
        display: block;
        width: 40%;
        padding: 5%;
      }
    }
  }

  @media screen and (max-width: 350px) {
    .button-link {
      max-width: 250px;
      &__demo {
        padding: 9px 48px 9px 18px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
    .button-link__demo {
        padding: 9px 48px 9px 18px;
      }
    
  }
