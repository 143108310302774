.game {
  &__animation {
    &-wrapper {
      position: relative;
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--inner {
      // display: none;
      position: relative;

      &.element {
        &-1,
        &-2,
        &-3,
        &-4 {
          opacity: 0;
          position: absolute;
          animation: imagine 1s ease-out;
        }
        &-2 {
          animation-delay: 1s;
        }
        &-3 {
          animation-delay: 2s;
        }
        &-4 {
          animation-delay: 3s;
        }
      }
    }
  }
  &__wrapper {
    max-width: 800px;
    width: 100%;
    margin: 100px auto 50px;
  }
  &__data {
    padding: 10px 20px 40px;
    width: 100%;
    background-color: $c-white;
    box-sizing: border-box;
    margin-top: 30px;
    border: 1px solid #dddee0;
    border-radius: 5px;
    &--num {
      text-align: left;
      transition: all 0.5s;
      text-align: left;
      transition: all 0.5s;
      display: inline-block;
      color: $c-black;
      &-selected {
        background-color: gray;
      }
    }
    &--abble-to-click {
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &__gameboard {
    padding: 20px;
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: $c-white;
    border: 1px solid #dddee0;
    border-radius: 0 0 5px 5px;

    &--title {
      width: 100%;
      background-color: #f5f6f8;
      height: 40px;
      border: 1px solid #dddee0;
      display: flex;
      border-radius: 5px 5px 0 0;
      position: relative;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;
      img {
        height: 20px;
        width: auto;
        margin-right: 10px;
      }
    }

    &--selector {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .topic-select {
        width: 44%;
        display: inline-block;
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid gray;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        margin: 5px 3%;

        &:hover {
          background-color: $c-cornflowerBlue;
          color: white;
        }
      }
    }
  }
}
.question-title {
  &--wrapper {
    position: relative;
    text-align: left;

    p {
      margin: 0;
    }
  }
  &--counter {
    position: absolute;
    left: -20px;
  }
}
.question--label {
  display: block;
  text-align: left;
  margin: 7px auto;
  position: relative;
  cursor: pointer;
  padding-left: 30px;

  &-correct {
    font-weight: bold;
    transform: scale(1.15);
    margin: 20px;
    margin-left: 47px;
    color: green;
  }
  &-false {
    font-weight: bold;
    transform: scale(1.15);
    margin: 20px;
    margin-left: 47px;
    color: red;
  }

  input[type="radio"] {
    margin-right: 15px;
    box-sizing: border-box;
    padding: 0;
    z-index: 0;
    position: absolute;
    left: -9999px;
  }
  input[type="radio"] + .question--circle {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 20;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  input[type="radio"]:checked + .question--circle {
    background: #2196f3;
    transform: scale(1);
  }

  &:before {
    z-index: 10;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
}
.question--time-left {
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  display: flex;
}

.question__choice {
  width: 50px;
  height: 20px;
  background: $c-white;
  border: 1px solid $c-black;
  text-align: center;
  display: inline-block;
  &--correct {
    background-color: green;
  }
  &--wrong {
    background-color: red;
  }
}

.game__date {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cover-parent {
  width: 100%;
  padding: 0 20px;
}
.title-info {
  font-size: 60%;
  font-weight: normal;
  color: gray;
}

@keyframes imagine {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    transform: scale(7);
  }
  100% {
    opacity: 0;
  }
}
