.wrapper {
  width: 100vw;
  max-width: $wrapper;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 0 20px;
  margin: 0 auto;
  &--center {
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
}
.grid {
  &-col-1 {
    flex-basis: 8%;
  }
  &-col-2 {
    flex-basis: 16%;
  }
  &-col-3 {
    flex-basis: 25%;
  }
  &-col-4 {
    flex-basis: 33%;
    max-width: 33%;
  }
  &-col-5 {
    flex-basis: 41%;
  }
  &-col-6 {
    flex-basis: 50%;
  }
  &-col-7 {
    flex-basis: 58%;
  }
  &-col-8 {
    flex-basis: 66%;
  }
  &-col-9 {
    flex-basis: 75%;
  }
  &-col-10 {
    flex-basis: 83%;
  }
  &-col-11 {
    flex-basis: 91%;
  }
  &-col-12 {
    flex-basis: 100%;
  }
  &-content-center {
    margin: 0 auto;
  }
}
.fb45 {
  flex-basis: 45%;
  margin: auto;
}
@media screen and (max-width: 800px) {
  [class*='grid-col-'] {
    flex-basis: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: unset;
  }
}
.grid-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid-flex-row {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.content-grid__section {
  margin: 40px 0;
}

.text-info {
  color: $c-bombay;
  line-height: 1.6em;
}
.text-shadow {
  text-shadow: 1px 1px 2px $c-white;
  font-size: 40px;
  font-weight: bolder;
}

.text-title {
  font-size: 30px;
  margin-bottom: 2.5em;
}

.line-separator {
  position: relative;
  &-bottom {
    margin-bottom: 120px;
    &:after {
      position: absolute;
      content: '';
      height: 50px;
      width: 2px;
      bottom: -80px;
      background-color: $c-silver;
    }
  }

  &-top {
    margin-top: 120px;
    &:after {
      position: absolute;
      content: '';
      height: 50px;
      width: 2px;
      top: -80px;
      background-color: $c-silver;
    }
  }
}

.img-wrapper {
  display: flex;
  height: 300px;
  img {
    object-fit: contain;
  }
}
.relative {
  position: relative;
}

@media only screen and (max-width: 500px){
  .fb45 {
  flex-basis: 100%;
  margin: auto;
}
}
