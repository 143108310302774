#topnav {
  &--wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
  }
  background: linear-gradient(rgb(0, 0, 255) 0%, rgb(255, 255, 255) 140%);
  padding-right: unset;
  padding-bottom: unset;
  padding-left: unset;
  padding: 5px;
  a {
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    color: white;
  }
}
.logOut {
border: 0px solid;
color: white;
background-color: transparent;
padding-left:15px;height: 20px;
float: right;
}

#floatnav{
  box-shadow: 0px 2px 5px rgb(225,225,227);
  background: white;
  min-height: 80px;
      display: flex;
    align-items: center;
}
.additional-logo {
      width: 90px;
    margin: 0px;
    padding: 0px;
    float: left;
}