.banner {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 20px 50px;
  box-shadow: 0 0 4px $c-bombay;
  width: 100%;
  &__img {
    width: 100%;
    display: flex;
    object-fit: cover;
    justify-content: center;
    .woman {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cloud {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;
      margin: 0 auto;
    }
  }
  &__text {
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    right: 12%;
    top: 20%;
    width: 35%;
    min-height: 30%;
    height: max-content;
    border-radius: 20px;
    z-index: 1;
    .actual-text {
      position: relative;
      z-index: 1;
      margin: 25px 0 0 10px;
      padding: 0 5px 5px 0;
      transform: skewX(45deg);
      p {
        color: $c-tundora;
        text-align: left;
        transform: skewX(-45deg);
        span {
          font-weight: 600;
          &.highlight {
            color: $c-sub;
          }
        }
      }
    }
  }

  &__buttons {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    div {
      width: 30%;
      text-transform: uppercase;
      font-family: 'Roboto';
      font-weight: 500;
    }
    div:nth-of-type(1) {
      margin: 0 10px 0 auto;
    }
    div:nth-of-type(2) {
      margin: 0 30px 0 10px;
    }
  }
}

.offer__section {
  width: 100%;
}

.offer {
  &__heading {
    display: flex;
    align-items: center;
    &--inner {
      display: flex;
      flex-grow: 1;
    }
    img {
      display: inline-block;
      width: 30px;
      margin: 20px;
    }
    h3 {
      margin: 0;
      color: $c-sub;
    }
  }
  &__filler {
    flex-grow: 1;
    margin-left: 30px;
    background-color: $c-sub;
    height: 2px;
    align-self: flex-end;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__content {
    list-style: none;
    li {
      list-style: none;
      position: relative;
      color: $c-tundora;
      text-align: left;
      padding: 20px;

      strong {
        color: $c-sub;
        font-weight: 500;
        font-weight: 700;
      }

      p {
        margin: 0;
      }

      &::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 23px;
        display: block;
        background-color: $c-main;
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }
    }
  }

  &__item {
    position: relative;
    color: $c-tundora;
    text-align: left;
    padding: 20px;
    .highlight {
      color: $c-sub;
      font-weight: 500;
      font-weight: 700;
    }
    &::before {
      content: '';
      position: absolute;
      left: -30px;
      top: 20px;
      display: block;
      background-color: $c-main;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }
  &__text {
    color: $c-tundora;
    text-align: justify;
    padding: 20px;
    .highlight {
      color: $c-sub;
      font-weight: 500;
      font-weight: 700;
    }
  }
  &__section {
    &--inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 50px;
    }
    &45 {
      flex-basis: 45%;
      margin: 20px auto;
    }
  }
  &__asterix {
    background-color: $c-main;
    border-radius: 40px;
    padding: 10px 50px;
    font-weight: 500;
    color: white;
  }
}

//kafelki produktów
.product-listing--section > .product-listing__mobile-info {
  display: none;
  position: absolute;
  right: 30px;
  top: 35px;
  color: #90ccff;
  font-size: 30px;
  border-radius: 100%;
  border: 2px solid #90ccff;
  width: 30px;
  height: 30px;
}
.product-listing {
  //dostęp czasowy
  box-sizing: border-box;
  min-width: 300px;
  min-height: 500px;
  height: auto;
  flex-basis: 45%;
  margin: 20px auto;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  box-shadow: 0px 0px 4px $c-bombay;

  &-description {
    p:first-of-type {
      text-align: left;
      margin-left: 45px;
      font-weight: 600;
    }
  }

  &__mobile-info {
    display: none;
    position: absolute;
    right: 30px;
    top: 45px;
    color: #90ccff;
    font-size: 30px;
    border-radius: 100%;
    border: 2px solid #90ccff;
    width: 30px;
    height: 30px;
  }
  &:hover {
    .product-listing__inner-wrapper {
      opacity: 1;
      height: 100%;
    }
    .product-background {
      opacity: 0.7;
    }
    ul,
    li,
    p {
      opacity: 1;
      color: $c-white;
    }

    p:first-of-type {
      text-align: left;
    }
  }
  .margin-sides {
    margin: 20px 20px;
  }
  .badge__background {
    position: absolute;
    right: -10px;
    left: -10px;
    top: -10px;
    bottom: -10px;
    background-color: $c-sub;
    opacity: 0.8;
    z-index: 1;
  }
  .product__badge {
    position: absolute;
    left: -20px;
    top: 40px;
    box-sizing: border-box;
    width: 150px;
    height: 50px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: flex-end;
    justify-content: center;
    color: white;
    padding: 10px;
    overflow: hidden;
    div:not(.badge__background) {
      position: relative;
      z-index: 1;
    }
    &--img {
      z-index: 1;
      position: absolute;
      left: 47px;
      top: 50%;
      transform: translateY(-50%) scale(1.5);
    }
  }
  .title-background {
    background-color: $c-main;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 15px;
  }
  .text-title {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    margin-bottom: 0 !important;
    h2 {
      position: relative;
      color: $c-white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font: 300 1.5rem 'Roboto';
      z-index: 1;
      img {
        margin-left: 40px;
      }
      div {
        margin: 0 20px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-left: 20px;
        }

        span {
          margin-right: 50px;
        }
      }
    }
    &::first-letter {
      text-transform: uppercase;
    }

    &--secondary {
      font-size: 28px;
      font-weight: 900;
      color: $c-main;
      margin: 0 auto 1rem;
    }
  }

  .text-info {
    color: $c-tundora;
    text-align: center;
  }
  .tile__footer {
    position: relative;
    display: flex;
    font: 700 1.5rem 'Roboto';
    margin-top: auto;
    margin-bottom: 30px;
    align-items: center;
    padding-left: 45px;
    color: $c-white;
    z-index: 3;
    .btn {
      text-transform: uppercase;
      font-weight: 300;
      padding: 10px 40px;
      margin-right: 15px;
      color: $c-pinkSalmon;
      margin-left: auto;
    }
  }

  &__separator {
    height: 5px;
    margin: 1rem -20px;
    width: calc(100% + 40px);
    background-color: $c-athensGray;
  }
  .product__image--wrapper {
    position: absolute;
    right: 30px;
    top: 30px;
    opacity: 1;
  }
  .product-background {
    background-color: $c-sub;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
  &__inner-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    transition: opacity 0.5s;
    height: 0;
    overflow: hidden;
    opacity: 0;
    border-radius: 10px;
    .text-title {
      color: $c-white;
      height: 126px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text-info {
      color: $c-black;
    }

    ul {
      list-style: none;
      position: relative;
      z-index: 3;
      margin-bottom: 0;
      min-width: 300px;
      li,
      p {
        text-align: left;
        color: $c-white;
        margin: 10px 5px;
        font-weight: 700;
        max-width: 90%;
        .highlight {
          font-size: 1.2rem;
        }
      }
    }
  }
  &:hover {
    & .product-background {
      opacity: 0.8;
    }
    li,
    p {
      opacity: 1;
      position: relative;
      z-index: 3;
    }
  }
  // oferta dla sekcji
  &--section {
    position: relative;
    box-shadow: 2px 0 5px 3px $c-bombay;
    background-size: cover;
    height: 340px;
    min-width: 300px;
    border-radius: 20px;
    flex-basis: 45%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .product-listing--section__additional-info {
      padding-top: 70px;
      text-align: left;
      color: white;
      padding-left: 10px;
      p {
        font-weight: bold;
      }
      ul > li {
        list-style-type: none;
        margin: 10px 5px;
      }
    }

    .product-background {
      position: absolute;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $c-main;
      opacity: 0;
    }
    .product__image {
      height: 100%;
      &--wrapper {
        position: absolute;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        left: 0;
        top: 10%;
        width: 100%;
        height: 80%;
        padding-right: 20px;
      }
    }
    .tile__footer {
      position: relative;
      display: flex;
      font: 700 1.5rem 'Roboto';
      margin-top: auto;
      align-items: center;
      padding-left: 45px;
      color: $c-white;
      z-index: 4;
      .btn {
        text-transform: uppercase;
        font-weight: 300;
        padding: 10px 40px;
        transform: translateY(-10%);

        &:hover {
          transform: translateY(-10%) scale(1.1);
        }
      }
    }
    .product__badge {
      box-sizing: border-box;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: -20px;
      top: 20px;
      height: 60px;
      min-width: 80%;
      max-width: max-content;
      border-radius: 15px;
      padding: 0 20px 0 50px;
      &:hover ~ .product-listing--section__inner-wrapper {
        opacity: 1;
        z-index: 4;
        cursor: auto;
      }
      .badge__background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $c-sub;
        opacity: 0.8;
        border-radius: 15px;
        z-index: 0;
        transition: background-color 0.5s;
      }
      .text-title {
        margin-bottom: 0;
        h4 {
          font-family: 'Roboto';
          position: relative;
          z-index: 2;
          color: white;
          text-align: left;
          font-size: 1.3rem;
          margin: 0;
          font-weight: 400;
        }
      }
      p {
        position: relative;
        text-align: left;
        z-index: 2;
        color: white;
        margin: 0;
        white-space: pre;
      }
    }
    &__inner-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.5s;
      z-index: 4;
      &:hover {
        opacity: 1;
        cursor: default;
      }
    }
    .wrapper__background {
      opacity: 0;
      transition: opacity 0.5s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      &:hover ~ .product-listing--section__inner-wrapper {
        opacity: 1;
        cursor: default;
        z-index: 4;
      }
    }
    &:hover {
      .product-background {
        opacity: 0.8;
      }
      .badge__background {
        background-color: $c-sub;
      }
      .wrapper__background {
        background-color: $c-main;
        opacity: 0.8;
      }
      .tile__footer {
        opacity: 1;
      }
      .product-listing--section__inner-wrapper {
        opacity: 1;
        cursor: default;
        z-index: 4;
      }
    }
  }
  &--script {
    position: relative;
    height: 180px;
    min-width: 300px;
    border-radius: 20px;
    flex-basis: 45%;
    margin: 20px auto;
    display: flex;
    overflow: hidden;
    .script-tile__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__inner-wrapper {
      flex-basis: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
.content-wrapper {
  padding: 40px;
  box-sizing: border-box;

  .text-uppercase {
    margin-top: 40px;
  }
}

p.highlight {
  color: $c-sub;
  text-align: left;
  font-weight: 600;
}
.wrapper--oferta {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  h1,
  h2 {
    font-family: 'Bradley Hand ITC';
    margin-right: auto;
    font-size: 2rem;
  }
}

.asterix {
  color: $c-main;
}

.script {
  &__offer {
    z-index: 1;
    h3 {
      font-weight: 300;
      font-size: 1.5rem;
      color: $c-white;
    }
  }
  &__info {
    z-index: 1;
    padding: 0;
    color: $c-white;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.icon {
  &-tiles {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  &-group {
    display: flex;
  }
  &-tile {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 18px;
    border: 1px solid $c-sub;
    margin: 10px;
    &__img {
      width: 100%;
    }
    &__text {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: $c-tundora;
      text-align: left;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .product-listing {
    &--section {
      &__inner-wrapper {
        .tile__footer {
          padding-left: 30px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .product-listing--section__additional-info {
    opacity: 0;
  }
  :hover .product-listing--section__additional-info {
    opacity: 1;
  }
  .content-wrapper {
    padding: 20px;
  }
  .offer__section--inner {
    padding: 0;
  }
  html {
    overflow-x: hidden;
  }
  .product-listing {
    .product__image--wrapper {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      img {
        // width: 50%;
        height: auto;
      }
    }
    &__inner-wrapper ul {
      padding-left: 5px;
    }
    &--section {
      &__inner-wrapper {
        opacity: 1;
        .tile__footer {
          padding-left: 15px;
          font-size: 1rem;
          .btn--section {
            background-color: $c-sub;
            color: $c-white;
          }
          .price {
            color: black;
          }
        }
      }
      &:hover {
        .product-background {
          opacity: 1;
        }
      }
    }
  }
  .offer {
    &__section45 {
      flex-basis: 100%;
    }
    &__section--inner {
      padding: 10px;
    }
    &__item {
      padding: 5px 0;
    }
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    height: 200px;
    .banner__buttons {
      flex-wrap: wrap;
      div {
        width: 100%;
        margin: 0;
      }
      div:nth-of-type(2) {
        margin: 10px 0 0;
      }
    }
  }
  .product-listing--section {
    height: 420px;
    & > .product-listing__mobile-info {
      display: block;
    }
    .product__image--wrapper {
      z-index: -1;
      height: 50%;
      margin-top: 15%;
    }
    .product-listing--section__additional-info {
      opacity: 0;
    }
    :hover .product-listing--section__additional-info {
      opacity: 1;
    }
  }

  .product-listing__mobile-info {
    display: block;
  }
}
